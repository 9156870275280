// tslint:disable: max-line-length
// tslint:disable: only-arrow-functions
import { UsuarioService } from "./../../services/usuario.service";
import { AgenciaService } from "./../../services/cadastro/agencia.service";
import { BetoCarreroService } from "src/app/services/turismo/beto-carrero.service";
import { ListaBetoCarreroComponent } from "../lista-beto-carrero/lista-beto-carrero.component";
import { cpf } from 'cpf-cnpj-validator';

import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import {
    Aereo,
    Hotel,
    Orcamento,
    Pacote,
    Reserva,
    Servico,
    ReservaPagamento,
    Busca,
    Carro
} from "src/app/model";
import {
    ReservaService,
    LoginService,
    AereoService,
    HotelService,
    ServicoService,
    VoucherService,
} from "src/app/services";
import { API, BACKGROUNDIMAGE, USA_CARRO, USA_LAMINAS, USA_BETO } from "src/app/app.config";
import { __read, __spreadArrays } from "tslib";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PacotelstService } from "src/app/services/turismo/pacotelst.service";
import { CarroService } from "src/app/services/turismo/carro.service";
import { BetoCarrero } from "src/app/model/turismo/beto-carrero.model";

@Component({
    selector: "app-orcamento",
    templateUrl: "./orcamento.component.html",
    styleUrls: [
        "./orcamento.component.scss",
        "../../../assets/styles/layoutPattern.scss",
    ],
})
export class OrcamentoComponent implements OnInit {
    @Input() servicos: Servico;
    @Input() Orcamento: any = new Reserva();
    // @Input() Orcamento: any;
    @Input() orcamento: Orcamento;
    @Input() hoteis: Hotel;
    @Input() reserva: Reserva;
    @Input() aereo: Aereo;
    @Input() pacote: Pacote;
    @Input() betoCarrero: BetoCarrero;
    @Input() carros: Carro;
    @Input() busca: any;
    objeto: any;
    aereoLoadded = false;
    hotelLoadded = false;
    servicoLoadded = false;
    servicoLoadded2 = false;
    betoLoadded = false;
    pagamentoLoadded = false;
    pacoteLoadded = false;
    apiLocale = API;
    backgroundImage = BACKGROUNDIMAGE;
    Arr = Array;
    dif: number;
    usaLamina = USA_LAMINAS;
    usaCarro = USA_CARRO;

    mensagensErroBeto: string[];
    mensagemSucessoBeto: string;
    USA_BETO: boolean = USA_BETO;
    // fazerTraducao: any;
    paises = [
        { name: 'Brasil', ddi: '+55', code: 'BR', mask: '(00) 00000-0000' },
        { name: 'Paraguai', ddi: '+595', code: 'PY', mask: '(000) 000 000' },
        { name: 'Peru', ddi: '+51', code: 'PE', mask: '(00) 000 0000' },
        { name: 'África do Sul', ddi: '+27', code: 'ZA', mask: '00 000 0000' },
        { name: 'Alemanha', ddi: '+49', code: 'DE', mask: '000 00000000' },
        { name: 'Argentina', ddi: '+54', code: 'AR', mask: '(00) 0000-0000' },
        { name: 'Austrália', ddi: '+61', code: 'AU', mask: '0 0000 0000' },   
        { name: 'Canadá', ddi: '+1', code: 'CA', mask: '(000) 000-0000' },
        { name: 'China', ddi: '+86', code: 'CN', mask: '000 0000 0000' },
        { name: 'Colômbia', ddi: '+57', code: 'CO', mask: '(000) 000 0000' },
        { name: 'Coreia do Sul', ddi: '+82', code: 'KR', mask: '00 000 0000' },
        { name: 'Espanha', ddi: '+34', code: 'ES', mask: '000 000 000' },
        { name: 'Estados Unidos', ddi: '+1', code: 'US', mask: '(000) 000-0000' },
        { name: 'França', ddi: '+33', code: 'FR', mask: '0 000 000 000' },
        { name: 'Índia', ddi: '+91', code: 'IN', mask: '0000 000 000' },
        { name: 'Itália', ddi: '+39', code: 'IT', mask: '000 000 0000' },
        { name: 'Japão', ddi: '+81', code: 'JP', mask: '000 000 0000' },
        { name: 'México', ddi: '+52', code: 'MX', mask: '(00) 0000 0000' },
        { name: 'Nova Zelândia', ddi: '+64', code: 'NZ', mask: '0 0000 0000' },
        { name: 'Portugal', ddi: '+351', code: 'PT', mask: '000 000 000' },
        { name: 'Reino Unido', ddi: '+44', code: 'UK', mask: '0000 000 000' },
        { name: 'Rússia', ddi: '+7', code: 'RU', mask: '(000) 000-0000' },
        { name: 'Suíça', ddi: '+41', code: 'CH', mask: '00 000 0000' }
    ];

    @ViewChild("reservarAereo") reservarAereo: ElementRef;
    @ViewChild("emitirAereo") emitirAereoModal: ElementRef;
    @ViewChild("cancelarAereo") cancelarAereo: ElementRef;
    @ViewChild("reservarHotel") reservarHotel: ElementRef;
    @ViewChild("reservarServico") reservarServico: ElementRef;
    @ViewChild("cancelarServico") cancelarServico: ElementRef;
    @ViewChild("pagamento") pagamentoModal: ElementRef;
    @ViewChild("reservarBetoCarrero") reservarBetoCarrero: ElementRef;
    @ViewChild("cancelarBetoCarrero") cancelarBetoCarrero: ElementRef;

    private routeSub: Subscription;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private aereoService: AereoService,
        private agenciaService: AgenciaService,
        private hotelService: HotelService,
        private servicoService: ServicoService,
        private betoCarreroService: BetoCarreroService,
        private reservaService: ReservaService,
        private loginService: LoginService,
        private usuarioService: UsuarioService,
        private voucherService: VoucherService,
        private pacoteService: PacotelstService,
        private carroService: CarroService,
        private modalService: NgbModal
    ) {
        this.empresa = JSON.parse(sessionStorage.getItem("loggedUser"));
        this.dataAtual = moment().format("YYYY-MM-DD");
    }
    pagamentosRealizados: ReservaPagamento[] = [];
    orcamentocliente: string = "";
    // Mensagens
    mensagemIdadeCertaVoltaViagem: string = null;
    mensagemSucessoPessoas: string = null;
    mensagensErroPessoas: string[] = null;
    mensagemSucessoHotel: string = null;
    mensagensErroHotel: string[] = null;
    mensagensSucessoPacote: string = "";
    mensagensErroPacote: string = "";
    mensagemSucessoAereo: string = null;
    mensagensErroAereo: string[] = null;
    mensagensErroReserva: string[];
    mensagemSucessoReserva: string;
    mensagensErroCarro: string[];
    mensagemSucessoCarro: string;

    // Booleans
    modalcomprovantecartao = false;
    passageirosConfirmados = false;
    IdadeCertaVoltaViagem = true;
    removeUltimoHotel = false;
    toggleConsultor = false;
    togglePessoas = false;
    auxAereoVolta = true;
    carregando = true;
    aprovaPix = false;
    auxAereoIda = true;
    perfilRoot: boolean;
    servicoCarregando: boolean;

    // Any
    alteracoesPessoas: any;
    agenteSelecionado: any;
    agenteListaRadio: any;
    chavePessoa: any;
    itemReserva: any;
    permissoes: any;
    dataAtual: any;
    Consultor: any;
    empresa: any;
    usuario: any;
    idTEMP: any;
    idTEMP2: any;
    aereos: any;
    agente: any;
    carregandoObj = false;
    spinnerAereo = false;
    spinnerServico = false;

    // Other
    // pacote = [];
    incluso: string;
    opcoesValores = [];
    agenteLista: [];
    agencias = [];
    agentes = [];
    perfis = [];
    erros = [];
    agenciaId: number;
    hoje = moment();
    num: Hotel;
    totalServico = 0;
    liberar_voucher = false;
    alterapaxcomreserva = false;
    podePagar = false;
    NomeAgencia = "";
    agenteNome = "";
    objModal: any;
    expandedDetails: boolean = false;

    distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    console(obj) {
        console.log(obj);
    }

    // limparMensagensBeto() {
    //     delete this.mensagensErroBeto;
    //     delete this.mensagemSucessoBeto;
    // }

    ngOnInit() {
        this.busca = JSON.parse(sessionStorage.getItem("busca"));

        this.dataAtual = moment().format("YYYY-MM-DD");
        this.idTEMP = this.route.snapshot.params.id;
        if (this.idTEMP !== undefined) {
            setTimeout(() => {
                this.carregarObjeto(this.idTEMP);
            }, 500);
        }

        this.usuario = this.loginService.user();
        const permi = window.localStorage.getItem("permission");
        if (permi != null) {
            this.permissoes = JSON.parse(atob(permi));
            this.aprovaPix =
                this.permissoes.indexOf("aprova_pix") > -1 ||
                this.permissoes.indexOf("admin") > -1 ||
                this.permissoes.indexOf("root") > -1;

            this.removeUltimoHotel =
                this.permissoes.indexOf("remove_ultimo_hotel") > -1 ||
                this.permissoes.indexOf("admin") > -1 ||
                this.permissoes.indexOf("root") > -1;

            this.liberar_voucher = this.permissoes.indexOf("liberar_voucher") > -1 ||
                this.permissoes.indexOf("admin") > -1 ||
                this.permissoes.indexOf("root") > -1;

            this.alterapaxcomreserva = this.permissoes.indexOf("alterapaxcomreserva") > -1;


        }

        // this.buscaAgentes();
        this.servicoCarregando = true;
    }

    onPaisChange(pessoa) {
        const pais = this.paises.find(c => c.code === pessoa.Pais);
        if (pais) {
            pessoa.mask = pais.mask;
        }
    }

    validaCaracterEspecial(value: string): boolean {
        const regex = /[^ \w]/;
        return regex.test(value);
    }

    consultarPagamento(pagamento) {
        this.mensagemSucessoReserva = '';
        this.reservaService.consultarPagamento(pagamento.Id).subscribe(
            dados => {
                this.atualizarOrcamento()
                switch (dados.Status) {
                    case 4:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: APROVADO';
                        break;
                    case 2:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: CONFIRMADO';
                        break;
                    case 3:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: RECUSADO';
                        break;
                    case 5:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: CANCELADO';
                        break;
                    case 6:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: ESTORNADO';
                        break;
                    case 0:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: PENDENTE';
                        break;
                }
            }, erro => {
                this.mensagensErroReserva = this.errorHandler(erro);
            })
    }

    abrirModalLaminas(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: "modal-grande", backdrop: 'static' });
    }

    abrirLinkPagamento(pagamento) {
        this.reservaService.gerarLinkPagamento(pagamento.Id).subscribe(
            dados => {
                window.open(dados);
                console.log(dados);
            },
            error => {
                console.log(error);
            });
    }

    ngAfterViewInit() {
        this.servicoCarregando = false;
    }

    temRC(tarifas) {
        return tarifas.some(x => x.ValorTaxaDUListagem > 0);
    }

    buscarOrcamento() {
        this.idTEMP = this.route.snapshot.params.id;
        this.reservaService.buscarOrcamento(this.idTEMP).subscribe(
            (dados) => {
                this.reserva = dados;

                setTimeout(() => {
                    this.buscaAgentes(true);
                }, 100);
            },
            (erro) => console.log(erro)
        );
    }

    buscaAgentes(primeira = false) {
        // const agencia = JSON.parse(localStorage.getItem("loggedUser"));
        //
        // if (agencia.agenciaId == (null || undefined)) {
        //   const agencia = atob(localStorage.getItem("g8h7d8"));
        //   const agenciaId = JSON.parse(agencia);
        //   this.idTEMP2 = agenciaId.Id;
        // } else {
        //   this.idTEMP2 = agencia.AgenciaId;
        // }

        this.usuarioService
            .buscarPorAgencia(this.agenteNome, 1, this.Orcamento.AgenciaId)
            .subscribe(
                (dados) => {
                    this.agentes = dados.Items;

                    if (primeira) {
                        let i = this.agentes
                            .map((x) => x.Id)
                            .indexOf(this.Orcamento.AgenteId);
                        this.Orcamento.Agente = this.agentes[i];
                    }
                },
                (erros) => {
                    console.log("Erro ao buscar usuarios da agencia: ", erros);
                    this.agentes = [];
                }
            );
    }

    onChangeAgencia(val) {
        this.agenciaService.buscarPorParametro(val, 1).subscribe(
            (dados) => {
                this.agencias = dados.Items.map(function (x) {
                    x.display_field = x.NomeFantasia;
                    return x;
                });
            },
            (erro) => {
                console.log(erro);
                this.agencias = [];
            }
        );
    }

    selecionaAgencia(agencia) {
        let primeira = false;
        if (this.Orcamento.AgenciaId != agencia.Id) {
            this.Orcamento.Agente = null;
            this.Orcamento.AgenteId = 0;
            primeira = true;
        }

        this.Orcamento.Agencia = agencia;
        this.Orcamento.AgenciaId = agencia.Id;
        this.buscaAgentes(primeira);
    }

    carregarObjeto(id) {
        this.reservaService.buscarOrcamento(id).subscribe(
            (dados) => {
                this.Orcamento = dados;
                this.agenciaId = this.Orcamento.AgenciaId;
                this.pacote = this.Orcamento.Hoteis.filter((x) => x.PacoteId != null);

                this.Orcamento.Pessoas = this.Orcamento.Pessoas.map((x) => {
                    if(x.Pais==''){
                        x.Pais = 'BR';
                    }
                    this.onPaisChange(x);
                    x.Nascimento = x.Nascimento
                        ? moment(x.Nascimento).format("DDMMYYYY")
                        : null;
                    return x;
                });

                this.Orcamento.Quartos = this.Orcamento.Pessoas.map(x => x.Quarto).filter(this.distinct).sort();

                this.Orcamento.Quartos = this.Orcamento.Quartos.map(x => {
                    return { quarto: x, pessoas: this.Orcamento.Pessoas.filter(y => y.Quarto === x) };
                });

                if (this.Orcamento.Hoteis && this.Orcamento.Hoteis.length > 0) {
                    this.Orcamento.Hoteis = this.Orcamento.Hoteis.sort(function (a, b) {
                        return a.Opcao - b.Opcao;
                    });

                    this.Orcamento.Hoteis = this.Orcamento.Hoteis.map(x => {
                        x.Quartos = x.Quartos.sort(function (a, b) {
                            return a.Pesquisa - b.Pesquisa;
                        });
                        return x;
                    });
                }

                this.Orcamento.Servicos = this.Orcamento.Servicos.map(x => {
                    if (this.isJson(x.ExtraIntegracao)) {
                        x.ExtraIntegracao2 = JSON.parse(x.ExtraIntegracao);
                    }
                    return x;
                });

                if (this.Orcamento.PacoteReservas && this.Orcamento.PacoteReservas.length > 0) {
                    this.Orcamento.PacoteReservas.forEach(x => {
                        x.Tarifa = x.Pacote.PacoteTarifas.find(y => y.Id == x.CodigoTarifa);
                    });
                }
                this.orcamentocliente = this.Orcamento?.Servicos[0]?.CodigoCliente?.split('-')[0];
                this.aereos = this.Orcamento.Aereos;
                this.hoteis = this.Orcamento.Hoteis;
                this.servicos = this.Orcamento.Servicos;
                this.carros = this.Orcamento.Carros;
                this.betoCarrero = this.Orcamento.betoCarrero;
                this.pagamentosRealizados = this.Orcamento.Pagamentos;
                this.togglePessoas = !this.paxValido();
                
                this.valoresOpcoes();
                this.verificaIncluso();
                this.IdadeCertaVoltaViagem = this.Orcamento.Pessoas.every(
                    (x) => x.IdadeCertaVoltaViagem === true
                );
                if (!this.IdadeCertaVoltaViagem) {
                    this.mensagemIdadeCertaVoltaViagem = `
                    <h5>Atenção:</h5>
                    <p>
                    Para que o sistema calcule corretamente o orçamento, é necessário informar uma data de nascimento compatível com a idade inserida na pesquisa.
                    Importante levar em consideração a idade dos passageiros ao término da viagem, ou seja, caso algum passageiro faça aniversário durante a viagem,
                    informe a idade que ele terá no dia do retorno. Essa informação é indispensável para cálculo de tarifa aérea,
                    INF/CHD/ADT e também para políticas de CHD/ADT em hotéis, serviços de receptivo e seguro viagem.
                    </p>`;
                }

                this.buscaAgentes();

                this.carregando = false;
                this.verificaConsultor();
                this.verificarPodePagar(id);
            },
            (erro) => {
                console.log("Erro ao buscar o objeto da reserva");
                console.log(erro);
                this.mensagensErroReserva = this.errorHandler(erro);
            }
        );
    }

    verificarPodePagar(id) {
        this.reservaService.verificarPodePagar(id).subscribe(
            () => {
                this.podePagar = true;
            },
            erro => {
                this.podePagar = false;
            });

    }

    public confirmarLiberar() {
        this.mensagensErroReserva = null;
        this.mensagemSucessoReserva = '';

        const resposta = window.confirm(
            'Tem certeza que deseja liberar os vouchers para a reserva ?'
        );

        if (resposta) {
            this.LiberarVoucher();
        }
    }


    LiberarVoucher() {
        this.reservaService.LiberarVoucher(this.Orcamento.Id).subscribe(
            dados => {
                this.mensagemSucessoReserva = 'Os vouchers da reserva foram liberados com sucesso !';

                this.Orcamento.LiberarVoucher = true;
            },
            erro => {
                console.log('Erro ao Liberar');
                console.log(erro);
                this.mensagensErroReserva = this.errorHandler(erro);
            },
        );
    }

    verificaConsultor() {
        this.Orcamento.Consultor == (null || undefined) ? (this.Consultor = false) : (this.Consultor = true);
    }

    paxValido() {
        return this.Orcamento.Pessoas.filter(
            //Verifica se algum pax possui dados inválidos
            pax => (
                !pax.Nome ||
                !pax.Sobrenome ||
                // ((pax.Nome.length + pax.Sobrenome.length) > 32) ||
                !pax.Nascimento ||
                (!pax.Email && this.Orcamento.Aereos.length > 0) ||
                (!pax.Telefone && this.Orcamento.Aereos.length > 0) ||
                !this.validaData(pax.Nascimento) ||
                !pax.Sexo)
        ).length == 0;
    }

    estornar(pagamento, index) {
        const resposta = window.confirm(`Tem certeza que deseja estornar o pagamento ?`);

        if (resposta) {
            this.reservaService.estornarPagamento(pagamento.Id).subscribe(
                dados => {
                    alert(`Pagamento ${dados.StatusDesc}!`);
                    this.atualizarOrcamento();
                }, erro => {
                    alert(erro.error.mensagem);
                });

        }
    }

    validaCPF(input, nacionalidade) {
        if (nacionalidade == "BR") {
            return cpf.isValid(input);
        }
        else {
            return true;
        }
    }

    validaData(data) {
        data = moment(data, "DDMMYYYY", true);
        const now = moment();
        return data && data.isValid() && data < now;
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    valoresOpcoes() {
        this.opcoesValores = [];
        //console.log(this.Orcamento);
        const totalAereo = this.Orcamento.Aereos.map((x) => x.ValorFechamento).reduce(
            function (a, b) {
                return a + b;
            },
            0
        );
        const totalServico = this.Orcamento.Servicos.map(
            (x) => x.ValorFechamento
        ).reduce(function (a, b) {
            return a + b;
        }, 0);
        const total = totalAereo + totalServico;
        let opcoes = this.Orcamento.Hoteis.map((x) => x.Opcao);
        opcoes = Array.from(new Set(opcoes));
        opcoes.forEach((element) => {
            const hoteis = this.Orcamento.Hoteis.filter(
                (x) =>
                    x.Opcao === element &&
                    x.Status !== 8 &&
                    x.Status !== 7 &&
                    x.Status !== 10
            );
            const totalHotel = hoteis
                .map((x) => x.ValorVendaMoedaDestino)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);
            const dadoshoteis = hoteis.map((x) => {
                x.CheckinOpcao = moment(x.Checkin).format("DD/MM/YYYY");
                x.CheckoutOpcao = moment(x.Checkout).format("DD/MM/YYYY");
                return x;
            });
            const totalPacote = hoteis
                .map((x) => x.ValorPacote)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);
            const totalOpcao = total + totalHotel + totalPacote;

            this.opcoesValores.push({
                opcao: element,
                valor: totalOpcao,
                dados: dadoshoteis,
            });

            //console.log(this.opcoesValores);

            this.opcoesValores.sort(function (a, b) {
                const result = a.opcao - b.opcao;
                // console.log("AQUI: ", result);
                return result;
            });
        });
    }

    verificaIncluso() {
        this.incluso = "";
        if (this.Orcamento.Aereos.length > 0) {
            this.incluso = "Aéreo";
        }

        if (this.Orcamento.Servicos.length > 0) {
            if (this.incluso !== "") {
                this.incluso += " + ";
            }
            this.incluso += "Serviço";
        }

        if (this.Orcamento.Seguros.length > 0) {
            if (this.incluso !== "") {
                this.incluso += " + ";
            }
            this.incluso += "Seguro";
        }
        if (this.Orcamento.BetoCarreroIngressos.length > 0) {
            if (this.incluso !== "") {
                this.incluso += " + ";
            }
            this.incluso += "Beto Carrero";
        }
    }

    orcamentoOpcoesCompleto() {
        let endereco = `${window.location.origin}/#/orcamentodownload/false/${this.Orcamento.Id}`;
        // window.open(this.reservaService.urlOrcamento(), "_blank");
        window.open(endereco, "_blank");
    }

    orcamentoOpcoesSimplificado() {
        let endereco = `${window.location.origin}/#/orcamentodownload/true/${this.Orcamento.Id}`;
        console.log("endereco", endereco)
        // window.open(this.reservaService.urlOrcamento(), "_blank");
        window.open(endereco, "_blank");
    }

    changeAgente(e) {
        this.agenteListaRadio = e.target.value;

        console.log("Change Agente: ", this.agenteListaRadio);
    }

    mudarAgente() {
        this.reservaService
            .AlterarAgenteReserva(this.idTEMP, this.agenteListaRadio)
            .subscribe(
                (dados) => {
                    this.agenteLista = dados;
                    this.agenteSelecionado =
                        this.agente.Nome + " " + this.agente.Sobrenome;
                    window.location.reload();
                },
                (erro) => console.log(erro)
            );
    }

    public errorHandler(erro): string[] {
        console.log(erro.error.mensagem);
        let mensagem = [];
        switch (erro.status) {
            case 0:
                mensagem.push("o servidor não respondeu, tente novamente mais tarde!");
                break;
            case 400:
                mensagem.push("Houve algum erro na execução, tente novamente!");
                break;
            case 401:
                mensagem.push("você não tem autorização para executar esta ação!");
                break;
            case 404:
                mensagem.push(erro.error.mensagem);
                break;
            case 406:
                mensagem.push(erro.error.mensagem);
                break;
            case 500:
                mensagem.push(erro.error.ExceptionMessage);
                break;
            default:
                mensagem.push(erro.statusText);
        }
        if (erro.error != null && erro.error.mensagens != null) {
            mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
        }
        return mensagem;
    }

    atualizarOrcamento() {
        this.carregarObjeto(this.Orcamento.Id);
    }

    noites(dataInicio, dataFim) {
        dataInicio = moment(dataInicio);
        dataFim = moment(dataFim);
        return dataFim.diff(dataInicio, "days");
    }

    // AEREO SECTION
    atualizarAereo(aereo) {
        const i = this.Orcamento.Aereos.map((x) => x.Id).indexOf(aereo.Id);

        this.Orcamento.Aereos[i] = aereo;
        this.atualizarOrcamento();
    }

    confirmarRemoverAereo(aereo) {
        const resposta = window.confirm(
            `Tem certeza que deseja excluir o aéreo: ${aereo.Id} ?`
        );

        if (resposta) {
            this.spinnerAereo = true;
            this.removerAereo(aereo);
        }
    }

    removerAereo(aereo) {
        this.limparMensagensAereo();
        this.aereoService.removerAereo(aereo).subscribe(
            (dados) => {
                this.Orcamento.Aereos = this.Orcamento.Aereos.filter(
                    (x) => x.Id !== dados.Id
                );
                this.mensagemSucessoAereo = `Aéreo ${dados.Id} removido com sucesso!`;
                this.atualizarOrcamento();
                this.spinnerAereo = false;
            },
            (erro) => {
                this.mensagensErroAereo = this.errorHandler(erro);
                this.spinnerAereo = false;
            }
        );
    }

    removerPacoteReserva(ReservaId) {
        this.limparMensagensPacote();
        this.pacoteService.removerPacoteReservaEstatico(ReservaId).subscribe(
            dados => {
                this.mensagensSucessoPacote = "Pacote Removido com Sucesso!"
                this.atualizarOrcamento();
            },
            erro => {
                this.mensagensErroPacote = this.errorHandler(erro)[0];
            })
    }

    limparMensagensPacote() {
        delete this.mensagensErroPacote;
        delete this.mensagensSucessoPacote;
    }

    limparMensagensAereo() {
        delete this.mensagemSucessoAereo;
        delete this.mensagensErroAereo;
    }

    emitirAereo(aereo) {
        this.limparMensagensAereo();
        this.aereoService.emitirAereo(aereo).subscribe(
            (dados) => {
                this.Orcamento.Aereos = this.Orcamento.Aereos.filter(
                    (x) => x.Id !== dados.Id
                );
                this.mensagemSucessoAereo = `Aéreo ${dados.Id} emitido com sucesso!`;
                this.atualizarOrcamento();
            },
            (erro) => {
                this.mensagensErroAereo = this.errorHandler(erro);
            }
        );
    }

    tarifarAereo(aereo) {
        this.limparMensagensAereo();
        this.aereoService.tarifarAereo(aereo).subscribe(
            (dados) => {
                aereo = dados;
                this.mensagemSucessoAereo = `Aéreo ${dados.Id} tarifado com sucesso com sucesso!`;
            },
            (erro) => {
                this.mensagensErroAereo = this.errorHandler(erro);
            }
        );
    }

    // HOTEL SECTION
    atualizarHotel(hotel) {
        const i = this.Orcamento.Hoteis.map((x) => x.Id).indexOf(hotel.Id);
        hotel.Cidade = this.Orcamento.Hoteis[i].Cidade;
        hotel.WebService = this.Orcamento.Hoteis[i].WebService;
        this.Orcamento.Hoteis[i] = hotel;
        this.atualizarOrcamento();
    }

    removerHotel(hotel) {
        if (confirm("Confirmar exclusão do hotel?")) {
            this.carregandoObj = true;

            // this.limparMensagensHotel();
            this.perfis = this.usuario.Perfis.map((x) => x.Role);
            if (this.perfis.indexOf("root") > -1) {
                this.perfilRoot = true;
            }

            if (
                !this.perfilRoot &&
                !this.removeUltimoHotel &&
                this.Orcamento.Aereos.length > 0 &&
                !this.Orcamento.AereoConsolidacao &&
                this.Orcamento.Hoteis.length === 1 &&
                (this.Orcamento.Servicos == null ||
                    this.Orcamento.Servicos.length === 0)
            ) {
                alert("É necessário manter pelo menos um item além do aéreo !");
            } else {
                this.hotelService.removerHotel(hotel).subscribe(
                    (dados) => {
                        this.Orcamento.Hoteis = this.Orcamento.Hoteis.filter(
                            (x) => x.Id !== dados.Id
                        );
                        alert("Hotel removido com sucesso");
                        // this.mensagemSucessoHotel = `Hotel ${dados.Id} removido com sucesso!`;
                        this.atualizarOrcamento();
                        this.carregandoObj = false;
                    },
                    (erro) => {
                        let mensagem = [];
                        switch (erro.status) {
                            case 0:
                                mensagem.push(
                                    "o servidor não respondeu, tente novamente mais tarde!"
                                );
                                break;
                            case 400:
                                mensagem.push("Houve algum erro na execução, tente novamente!");
                                break;
                            case 401:
                                mensagem.push(
                                    "você não tem autorização para executar esta ação!"
                                );
                                break;
                            case 404:
                                mensagem.push("Solicitação inválida!");
                                break;
                            case 406:
                                mensagem.push(erro.error.mensagem);
                                break;
                            case 500:
                                mensagem.push(erro.error.ExceptionMessage);
                                break;
                            default:
                                mensagem.push(erro.statusText);
                        }
                        if (erro.error != null && erro.error.mensagens != null) {
                            mensagem = __spreadArrays(
                                [mensagem],
                                __read(erro.error.mensagens)
                            );
                        }
                        alert(mensagem);
                        this.carregandoObj = false;
                    }
                );
            }
        }
    }

    abrirModal(content, obj) {
        this.objModal = obj;
        this.modalService.open(content, { centered: true, size: "lg" });
    }

    cancelarHotel(hotel) {
        let mensagem = [];

        if (confirm("Confirmar cancelamento do hotel?")) {
            this.carregandoObj = true;

            // this.limparMensagensHotel();
            this.hotelService.cancelarHotel(hotel).subscribe(
                (dados) => {
                    hotel = dados;
                    mensagem.push("Cancelamento efetivado!");
                    this.carregandoObj = false;
                    this.atualizarOrcamento();
                    alert(mensagem);
                },
                (erro) => {
                    switch (erro.status) {
                        case 0:
                            mensagem.push(
                                "o servidor não respondeu, tente novamente mais tarde!"
                            );
                            break;
                        case 400:
                            mensagem.push("Houve algum erro na execução, tente novamente!");
                            break;
                        case 401:
                            mensagem.push(
                                "você não tem autorização para executar esta ação!"
                            );
                            break;
                        case 404:
                            mensagem.push("Solicitação inválida!");
                            break;
                        case 406:
                            mensagem.push(erro.error.mensagem);
                            break;
                        case 500:
                            mensagem.push(erro.error.ExceptionMessage);
                            break;
                        default:
                            mensagem.push(erro.statusText);
                    }
                    if (erro.error != null && erro.error.mensagens != null) {
                        mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
                    }
                    alert(mensagem);
                    this.carregandoObj = false;
                }
            );
        }
    }

    // limparMensagensHotel() {
    //   delete this.mensagemSucessoHotel;
    //   delete this.mensagensErroHotel;
    // }

    atualizarStatusHotel(hotel) {
        // this.limparMensagensHotel();
        hotel.carregando = true;
        const statusAtual = hotel.Status;
        this.hotelService.atualizarStatusHotel(hotel).subscribe(
            (dados) => {
                hotel = dados;
                this.atualizarHotel(hotel);
                if (hotel.Status === statusAtual) {
                    this.mensagemSucessoHotel = `Hotel ${hotel.Nome} segue com o mesmo status no fornecedor!`;
                    alert(this.mensagemSucessoHotel);
                } else {
                    // tslint:disable-next-line:max-line-length
                    this.mensagemSucessoHotel = `Hotel ${hotel.Nome
                        } teve o Status Alterado de ${this.getStatus(
                            statusAtual
                        )} para ${this.getStatus(hotel.Status)}!`;
                    alert(this.mensagemSucessoHotel);
                }

                hotel.carregando = false;
            },
            (erro) => {
                hotel.carregando = false;
                this.mensagensErroHotel = this.errorHandler(erro);
                alert(this.mensagensErroHotel);
            }
        );
    }

    // SERVICOS SECTION
    atualizarServico(servico) {
        const i = this.Orcamento.Servicos.map((x) => x.Id).indexOf(servico.Id);
        this.Orcamento.Servicos[i] = servico;
    }

    removerServico(servico) {
        if (confirm("Confirmar exclusão do serviço?")) {
            if (
                !this.perfilRoot &&
                !this.removeUltimoHotel &&
                this.Orcamento.Aereos.length > 0 &&
                !this.Orcamento.AereoConsolidacao &&
                this.Orcamento.Servicos.length === 1 &&
                (this.Orcamento.Seguros === null ||
                    this.Orcamento.Seguros.length === 0) &&
                (this.Orcamento.Hoteis == null || this.Orcamento.Hoteis.length === 0)
            ) {
                alert("É necessário manter pelo menos um item além do aéreo !");
            } else {
                this.servicoService.removerServico(servico).subscribe(
                    (dados) => {
                        this.Orcamento.Servicos = this.Orcamento.Servicos.filter(
                            (x) => x.Id !== dados.Id
                        );
                        alert("Serviço removido com sucesso");
                        this.atualizarOrcamento();
                    },
                    (erro) => {
                        this.mensagensErroReserva = this.errorHandler(erro);
                        this.spinnerServico = true;
                        alert(erro);
                    }
                );
            }
        }
    }


    salvarDadosPessoas() {
        this.limparMensagensPessoas();
        this.Orcamento.Pessoas = this.Orcamento.Pessoas.map((x) => {
            x.Nascimento = moment(x.Nascimento, "DDMMYYYY");
            return x;
        });
        this.reservaService.salvarDadosPessoas(this.Orcamento.Pessoas).subscribe(
            (dados) => {
                this.Orcamento.Pessoas = dados;

                this.Orcamento.Pessoas = this.Orcamento.Pessoas.map((x) => {
                    
                    x.Nascimento = x.Nascimento
                        ? moment(x.Nascimento).format("DDMMYYYY")
                        : null;
                    x.Nome = x.Nome.trim();
                    x.Sobrenome = x.Sobrenome.trim();
                    x.Email = x.Email ? x.Email.trim() : null;
                    this.onPaisChange(x);
                    return x;
                });
                this.Orcamento.Quartos = this.Orcamento.Pessoas.map((x) => x.Quarto)
                    .filter(this.distinct)
                    .sort();
                this.Orcamento.Quartos = this.Orcamento.Quartos.map((x) => {
                    return {
                        quarto: x,
                        pessoas: this.Orcamento.Pessoas.filter((y) => y.Quarto === x),
                    };
                });
                this.IdadeCertaVoltaViagem = dados.every(
                    (x) => x.IdadeCertaVoltaViagem === true
                );
                if (!this.IdadeCertaVoltaViagem) {
                    this.mensagemIdadeCertaVoltaViagem = `<h5>Atenção:</h5>
          <p>
          Para que o sistema calcule corretamente o orçamento, é necessário informar uma data de nascimento compatível com a idade inserida na pesquisa.
          Importante levar em consideração a idade dos passageiros ao término da viagem, ou seja, caso algum passageiro faça aniversário durante a viagem,
          informe a idade que ele terá no dia do retorno. Essa informação é indispensável para cálculo de tarifa aérea,
          INF/CHD/ADT e também para políticas de CHD/ADT em hotéis, serviços de receptivo e seguro viagem.
          </p>`;
                }
                this.mensagemSucessoPessoas = "Dados atualizados com sucesso!";
            },
            (erro) => {
                console.log("Erro ao salvar pessoas!");
                console.log(erro);
                this.mensagensErroPessoas = this.errorHandler(erro);
            }
        );

        alert("PAX confirmados/editados");
    }

    limparMensagensPessoas() {
        delete this.mensagemSucessoPessoas;
        delete this.mensagensErroPessoas;
        delete this.mensagemIdadeCertaVoltaViagem;
    }

    carregar(obj, tipo) {
        this.objeto = obj;

        switch (tipo) {
            case "hotel":
                this.hotelLoadded = true;
                setTimeout(() => {
                    this.reservarHotel.nativeElement.click();
                }, 100);
                break;
            case "servico":
                this.servicoLoadded = true;
                setTimeout(() => {
                    this.reservarServico.nativeElement.click();
                }, 100);
                break;
            case "cancelarservico":
                //   this.servicoLoadded2 = true;
                //  setTimeout(() => {
                //   alert(this.servicoLoadded2);

                this.reservarServico.nativeElement.click();
                //     //this.cancelarServico
                //   }, 500);
                break;
            case "pagamento":
                this.pagamentoLoadded = true;
                setTimeout(() => {
                    this.pagamentoModal.nativeElement.click();
                }, 100);
                break;
            case "reservaraereo":
                this.aereoLoadded = true;
                setTimeout(() => {
                    this.reservarAereo.nativeElement.click();
                }, 100);
                break;
            case "emitiraereo":
                this.aereoLoadded = true;
                setTimeout(() => {
                    this.emitirAereoModal.nativeElement.click();
                }, 100);
                break;
            case "cancelaraereo":
                this.aereoLoadded = true;
                setTimeout(() => {
                    this.cancelarAereo.nativeElement.click();
                }, 100);
                break;
        }
    }

    getStatus(status) {
        switch (status) {
            case 0:
                return "PENDENTE";
            case 1:
                return "AGUARDANDOINTEGRADOR";
            case 2:
                return "PARCIALMENTECONFIRMADO";
            case 3:
                return "RESERVADO";
            case 4:
                return "CONFIRMADO";
            case 7:
                return "PROBLEMATECNICO";
            case 8:
                return "CANCELADA";
        }
    }

    selecionaConsultor(consultor) {
        if (
            this.Orcamento.Consultor != null &&
            consultor.Id === this.Orcamento.Consultor.Id
        ) {
            // return true;
        }

        this.reservaService
            .alterarConsultor(this.Orcamento.Id, consultor.Id)
            .subscribe(
                (dados) => {
                    this.Consultor = consultor;
                    this.Orcamento.Consultor = consultor;
                    this.mensagemSucessoReserva = "Consultor alterado com sucesso";
                },
                (erro) => {
                    console.log(erro);
                    this.mensagensErroReserva = this.errorHandler(erro);
                    alert("Erro ao selecionar consultor");
                }
            );
    }

    alternaAuxAereoIda() {
        this.auxAereoIda = !this.auxAereoIda;
    }

    alternaAuxAereoVolta() {
        this.auxAereoVolta = !this.auxAereoVolta;
    }

    somaServico(x, i: any) {
        if (x == 0) {
            this.totalServico = i;
        } else {
            this.totalServico = +this.totalServico + i;
        }
    }

    atualizarPagamentos(pagamentos) {
        this.carregarObjeto(this.Orcamento.Id);
    }

    // adicionarItem(event, item) {
    //     const x = window.sessionStorage.getItem("busca");
    //
    //     if (x != null) {
    //         let busca;
    //         busca = JSON.parse(x);
    //
    //         busca.tipoBusca.aereo = false;
    //         busca.tipoBusca.hotel = false;
    //         busca.tipoBusca.transfer = false;
    //         busca.tipoBusca.ticket = false;
    //         busca.tipoBusca.carro = false;
    //         busca.tipoBusca.seguro = false;
    //
    //         sessionStorage.removeItem("busca");
    //
    //         switch (item) {
    //             case "aereo":
    //                 busca.tipoBusca.aereo = true;
    //                 sessionStorage.setItem("busca", JSON.stringify(busca));
    //                 this.router.navigate([`adicionar/${this.Orcamento.Id}/aereo`]);
    //                 break;
    //
    //             case "hotel":
    //                 busca.tipoBusca.hotel = true;
    //                 window.sessionStorage.removeItem("busca");
    //                 window.sessionStorage.setItem("busca", JSON.stringify(busca));
    //                 this.router.navigate([`adicionar/${busca.orcamentoId}/hotel`]);
    //                 break;
    //
    //             case "servico":
    //                 busca.tipoBusca.ticket = true;
    //                 window.sessionStorage.removeItem("busca");
    //                 window.sessionStorage.setItem("busca", JSON.stringify(busca));
    //                 this.router.navigate([`adicionar/${busca.orcamentoId}/servico`]);
    //                 break;
    //         }
    //         console.log(busca);
    //
    //     }
    //     this.router.navigate([`busca/${this.Orcamento.Id}`]);
    //     window.localStorage.setItem(
    //         "g8h7d8",
    //         btoa(JSON.stringify(this.Orcamento.Agencia))
    //     );
    // }

    adicionarItem(event, item) {
        let busca;
        busca = new Busca();

        busca.tipoBusca.aereo = false;
        busca.tipoBusca.hotel = false;
        busca.tipoBusca.transfer = false;
        busca.tipoBusca.ticket = false;
        busca.tipoBusca.carro = false;
        busca.tipoBusca.seguro = false;
        busca.tipoBusca.beto = false;

        switch (item) {
            case 'aereo':
                busca.tipoBusca.aereo = true;
                break;
            case 'hotel':
                busca.tipoBusca.hotel = true;
                break;
            case 'transfer':
                busca.tipoBusca.transfer = true;
                break;
            case 'servico':
            case 'ticket':
                busca.tipoBusca.ticket = true;
                break;
            case 'carro':
                busca.tipoBusca.carro = true;
                break;
            case 'seguro':
                busca.tipoBusca.seguro = true;
                break;
            case 'beto':
                busca.tipoBusca.beto = true;
                break;
        }

        window.sessionStorage.removeItem('busca');
        window.sessionStorage.setItem('busca', JSON.stringify(busca));
        //  }

        window.localStorage.setItem('g8h7d8', btoa(JSON.stringify(this.Orcamento.Agencia)));
        if (!busca.tipoBusca.beto) {
            this.router.navigate([`busca/${this.Orcamento.Id}`]);
        }
        else {
            // this.router.navigate([`beto-carrero/${this.Orcamento.Id}`]);
            this.router.navigate([`beto-carrero/${this.Orcamento.Id}`]);
        }


    }

    setDataNascimento(pessoa) {
        const novaIdade = this.getIdade(pessoa.Nascimento);
        if (pessoa.Tipo == 0) {
            pessoa.Idade = novaIdade;
        }
    }

    getIdade(nascimento): number {
        const datanasc = moment(nascimento, "DD/MM/YYYY");
        const timeDiff = Math.abs(Date.now() - datanasc.valueOf());
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        return age;
    }

    imprimirVoucher(tipo, item) {
        if (!item.VoucherKey) {

            let endereco = `${window.location.origin}/voucher/`;
            this.voucherService.gerarVoucher(tipo, item, endereco)
                .subscribe(
                    dados => {

                        item.VoucherKey = dados;
                        this.exibeVoucher(item.VoucherKey);
                    },
                    error => {
                        switch (item) {
                            case 'aereo': this.mensagensErroAereo = this.errorHandler(error); break;
                            case 'hotel': this.mensagensErroHotel = this.errorHandler(error); break;
                            case 'pacote': this.mensagensErroPacote = this.errorHandler(error)[0]; break;
                            //case 'seguro': this.mensagensErroSeguro = this.errorHandler(error); break;
                            default: this.errorHandler(error); break;

                        }
                    });

        } else
            return this.exibeVoucher(item.VoucherKey);

    }

    exibeVoucher(VoucherKey) {
        window.open(`/#/voucher/${VoucherKey}`, "_blank");
    }

    confirmarRemoverCarro(carro) {

        const resposta = window.confirm(`Tem certeza que deseja excluir o carro: ${carro.Descricao} ?`)

        if (resposta) {
            this.removerCarro(carro);
        }
    }

    removerCarro(carro) {
        this.limparMensagensAereo();
        this.carroService.removerCarro(carro).subscribe(
            dados => {

                this.Orcamento.Carros = this.Orcamento.Carros.filter(x => x.Id !== dados.Id);
                this.mensagemSucessoCarro = `Carro ${dados.Id} removido com sucesso!`;
                this.atualizarOrcamento();
            },
            erro => {
                this.mensagensErroAereo = this.errorHandler(erro);
            });
    }

    atualizarCarro(carro) {
        const i = this.Orcamento.Carros.map((x) => x.Id).indexOf(carro.Id);
        this.Orcamento.Carros[i] = carro;
        this.atualizarOrcamento();
    }

    atualizarBeto(betoCarrero) {
        console.log(betoCarrero);
        const i = this.Orcamento.BetoCarreroIngressos.map((x) => x.Id).indexOf(betoCarrero.Id);
        this.Orcamento.BetoCarreroIngressos[i] = betoCarrero;
    }

    limparMensagensBeto() {
        this.mensagemSucessoBeto = '';
        this.mensagensErroBeto = [];
    }

    removerBetoCarrero(betoCarrero) {
        if (confirm("Confirmar exclusão do serviço?")) {

            this.betoCarreroService.removerIngresso(betoCarrero.Id).subscribe(
                dados => {
                    this.limparMensagensBeto();
                    this.mensagemSucessoReserva = `Ingresso ${dados.Id} removido com sucesso!`;
                    this.atualizarOrcamento();
                    // this.ValoresOpcoes();
                },
                erro => {
                    this.mensagensErroReserva = this.errorHandler(erro);
                });

        }
    }

    fecharModal() {
        this.modalService.dismissAll();
    }
}
