<div class="modal-body">
  <div class="progress">
    <div class="progress-bar bg-success" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">Dados</div>
    <div class="progress-bar" [ngClass]="[this.progress > 1 ? 'bg-success' : 'bg-secondary']" role="progressbar" style="width: 34%" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100">Políticas da cia aérea</div>
    <div class="progress-bar" [ngClass]="[this.progress > 2 ? 'bg-success' : 'bg-secondary']" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">Reserva</div>
  </div>

  <div class="dados" *ngIf="progress == 1">
    <h3 class="title">{{"Verifique os dados antes de prosseguir" | translate}}!</h3>
      <app-orcamento-aereo-card [aereo]="aereo"></app-orcamento-aereo-card>
    <div class="row">
      <div class="col">
        <table class="table">
          <thead>
            <tr>
              <th>{{"Tipo" | translate}}</th>
              <th>{{"Qtde" | translate}}</th>
              <th>{{"Tarifa(un)" | translate}}</th>
              <th>{{"Taxas de serviço(un)" | translate}}</th>
              <th>{{"Taxas(un)" | translate}}</th>
              <th>{{"Subtotal" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tarifa of aereo.PaxesTarifa">
              <td>
                {{(tarifa.TipoPax == 0 ? 'Adulto' : (tarifa.TipoPax == 1 ? 'Criança' : 'Bebê')) | translate}}
              </td>
              <td>{{tarifa.QtdPax}}</td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTarifaListagem | currency:'BRL':'':'1.2-2' }}
              </td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTaxaServicoListagem | currency:'BRL':'':'1.2-2' }}</td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTaxaListagem | currency:'BRL':'':'1.2-2' }}
              </td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.SubTotalListagem | currency:'BRL':'':'1.2-2' }}
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="aereoRetarifado && aereoRetarifado.VendaTotal !== aereo.VendaTotal">
          <h2>{{"Alterou o Valor do Aéreo" | translate}}</h2>
          <p>{{"Valor Antigo" | translate}}: {{aereo.VendaTotal | currency:'BRL':true:'1.2-2' }}</p>
          <p>{{"Valor Atual" | translate}}: {{aereoRetarifado.VendaTotal | currency:'BRL':true:'1.2-2' }}</p>
          <p>
            <label ngbButtonLabel class="btn btn-primary tab-buscador">
              <input type="checkbox" ngbButton [(ngModel)]="cienteAlterouValor"> {{"Estou Ciente" | translate}}
            </label>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="politica" *ngIf="progress == 2">
    <h3>{{"Leia atentamente as regras tarifárias da companhia" | translate}}</h3>
    <div *ngFor="let regra of regrasTarifarias" title="{{regra?.Tarifa}}">
      <p [innerHTML]=regra?.Regra></p>
    </div>
  </div>

  <div id="reserva" *ngIf="progress == 3">
    <h4>{{"Confirmação" | translate}}</h4>
    <p>
      <span class="badge rounded-pill bg-danger">Loc: {{aereo.LocalizadorAereo}}</span> | <strong>{{"Data Limite" | translate}}:
      </strong> {{aereo.LimiteTempoEmissao | date :'dd/MM/yyyy HH:mm:ss'}}
    </p>
  </div>
</div>
<div class="modal-footer" style="display:block">
  <div class="row" *ngIf="mensagensErro?.length > 0">
    <div class="alert alert-danger" style="width: 100%">
      <ul>
        <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
    <div class="alert alert-success" style="width: 100%">
      {{mensagemSucesso}}
    </div>
  </div>

  <div class='row text-r'>
    <div class='col text-end'>
      <app-loading-small *ngIf="carregando"></app-loading-small>

      <button class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()" *ngIf="progress < 3">
        {{'Cancelar' | translate}}
      </button>

      <button class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()" onclick="window.location.reload()" *ngIf="progress >= 3">
        {{'Fechar' | translate}}
      </button>

      <button class="btn btn-outline-success" [class.apagado]="carregando" [disabled]="carregando"
      (click)="voltar()" *ngIf="(progress > 1) && (progress < 3)">Voltar</button>

      <button class="btn btn-success" [class.apagado]="carregando"
      (click)="buscarPoliticasCia()" *ngIf="progress == 1"
      [disabled]="carregando || (aereoRetarifado?.VendaTotal !== aereo?.VendaTotal && !cienteAlterouValor)">
        {{"Avançar" | translate}}
      </button>

      <button class="btn btn-success" [class.apagado]="carregando" [disabled]="carregando"
      (click)="reservarAereo()" *ngIf="progress == 2">
        Reservar
      </button>

    </div>
  </div>
</div>
